<template>
  <div class="pl-8 pr-8">
   
    <div class="forms-index__banner-desktop ml-3 mr-3">
      <v-row>
        <v-col class="pl-0 pr-0 text-left " cols="12">
          <h1 class="forms__title">BRM Pregunta</h1>
        </v-col>
      </v-row>
    </div>

     <!-- Desktop -->
    <v-col cols="12" class="index-form__component-desktop" v-if="!isMobile">
      <v-row class="pt-3">
        <v-col class="pl-0" cols="6">
          <PendingForm class="v-step-mobile-22"></PendingForm>
        </v-col>
        <v-col class="pr-0" cols="6">
          <Completed class="v-step-mobile-23"></Completed>
        </v-col>
      </v-row>
    </v-col>

    <!-- mobile -->
    <div v-else>
      <v-row class="ml-0 mr-0 forms-spacing">
      <div class="pt-7 forms-index__banner-mobile">
        <v-col
          justify="center"
          style="text-align: left"
          cols="12"
          class="py-4 px-0"
        >
          <div class="d-flex forms-spacing">
            <v-btn @click="$router.go(-1)" x-small class="mt-0 mb-2" fab icon>
              <img width="15" src="../../assets/icon/navigate_before.png" />
            </v-btn>
            <p class="text-title-1">
              <label
                style="padding-top: 5%"
                class="text-apps-blue-sm txt-capitalize"
              >
                {{ name }},
              </label>
              <label style="padding-top: 5%" class="text-apps-blue-sm">
                acá encontrará
              </label>
            </p>
          </div>
          <p style="text-align: left" class="text-apps-sm mb-1 ml-9">
            Todas sus encuestas
          </p>
        </v-col>
      </div>
      <v-col cols="12" class="py-0 px-0 index-form__component-mobile">
        <PendingForm class="v-step-mobile-22"></PendingForm>
      </v-col>
    </v-row>
    <v-row
      justify="center"
      class="ml-0 mr-0 forms-spacing index-form__component-mobile"
    >
      <v-col cols="12" class="py-0 px-0">
        <Completed class="v-step-mobile-23"></Completed>
      </v-col>
    </v-row>
    </div>


  </div>
</template>
<script>
import PendingForm from "./PendingForm";
import Completed from "./Completed";
export default {
  components: {
    PendingForm,
    Completed,
  },
  data() {
    return {
      name: "",
      infoToken: {
        document: "",
      },
      isMobile: false
    };
  },
  methods: {
    getToken() {
      var token = localStorage.getItem("token");
      var div = token.split(".");
      if (div.length >= 2) {
        this.infoToken = JSON.parse(atob(div[1]));

        var nameList = this.infoToken.fullName.split(" ");

        if (nameList.length > 1) {
          if (nameList[0] != "") {
            this.name = nameList[0];
          } else {
            this.name = nameList[1];
          }
        } else {
          this.name = "";
        }
      } else {
        this.infoToken = {
          document: "",
        };
      }
    },
    checkIfMobile() {
      this.isMobile = window.innerWidth <= 1023;
    },
  },
  created() {
    this.getToken();
    this.checkIfMobile();
    window.addEventListener('resize', this.checkIfMobile);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkIfMobile);
  },
};
</script>

<style scoped>
@import "./../../assets/css/main.less";

.navidad-text{
  display: flex;
}
.forms-index__banner-desktop {
  display: block;
}
.desktop-hidden {
  display: none;
}

.mb-sm-index-forms {
  display: none;
}
.forms-index__banner-mobile {
  display: none;
}

.forms-spacing {
  display: none;
}

.forms__title {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 59px;
  color: #353535;
}

.index-form__component-mobile {
  display: none;
}

.index-form__component-desktop {
  display: block;
}

/* LAPTOP 4k */
@media (max-width: 1900px) {
}

/* LAPTOP LARGE */
@media (max-width: 1600px) {
}

/* LAPTOP LARGE */
@media (max-width: 1400px) {
}

/* LAPTOP NORMAL */
@media (max-width: 1200px) {
  /* .forms-spacing {
    padding-left: 32px !important;
    padding-right: 32px !important;
    margin-left: 12px !important;
    margin-right: 12px !important;
  } */
}

/* TABLET */
@media (max-width: 1023px) {
  .index-form__component-desktop {
    display: none;
  }

  .index-form__component-mobile {
    display: block;
  }
  .forms-index__banner-desktop {
    display: none !important;
  }
  .text-apps-blue-sm {
    font-weight: 300;
    font-size: 25px;
    line-height: 30px;
    color: #466be3;
  }
  .text-apps-sm {
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    color: #626262;
    font-family: "AsapRegular";
  }
  .forms-index__banner-mobile {
    display: block;
  }

  .forms-spacing {
    display: block;
    /* padding-left: 12px !important;
    padding-right: 12px !important; */
    margin-left: -10px !important;
    margin-right: -10px !important;
  }
}

/* MOBILE L - 767px*/
@media (max-width: 600px) {
  .forms-index__banner-desktop {
    display: none !important;
  }

  .mb-sm-index-forms {
    padding-top: 80px;
    padding-bottom: 80px;
    display: block;
  }

  .text-title-xl {
    color: #466be3 !important;
  }

  .text-apps-blue-sm {
    font-weight: 300;
    font-size: 25px;
    line-height: 30px;
    color: #466be3;
  }
  .text-apps-sm {
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    color: #626262;
    font-family: "AsapRegular";
  }
  .forms-index__banner-mobile {
    display: block;
  }
}
/* MOBILE M */
@media (max-width: 375px) {
}

/* MOBILE S */
@media (max-width: 320px) {
}
</style>
